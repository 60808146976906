import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import { findCountryByCode } from '../../helpers/utils'
import ManageSupplier from './components/ManageTea'
import ManageTea from './components/ManageTea'

export default function RawTeaInfo() {

    const {id} = useParams();
    const [gardenInfo,setGardenInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [showDelete,setShowDelete] = useState(false)
    const [activeTab,setActiveTab] = useState('orders')
    const navigate=  useNavigate()
    const [showAddProduct,setShowAddProduct] = useState({status:false,edit:false})

    const RightContent = ()=>
    {
        return <div>
          {activeTab === 'profile' ? <div>

            <button type='button' className='btn-md text-xs font-bold bg-[#b03333] hover:bg-[#b00000] mr-2' onClick={()=>setShowDelete(true)}>Delete Raw Tea</button>
            <button type='button' className='btn-md text-xs font-bold' onClick={()=>setShowEdit(true)}>Edit Raw Tea</button>
            </div> : null}
        </div>
    }

    async function getGardenInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/tea/${id}?populate=true`)
            setGardenInfo(res.data)
        } catch (error) {
            toast('Error getting Raw Tea')
        }
    }
    useEffect(()=>
    {
        getGardenInfo();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/tea/${id}`,data); 
            await getGardenInfo()
            setShowEdit(false)
            InfoToast('Garden Updated')
        } catch (error) {
            console.log(error)
            ErrorToast(error.response)
        }
    }

    const onDelete = async(e)=>
        {
            try 
            {
                e.preventDefault()
                let res = await authAxios.delete(`${process.env.REACT_APP_BASE_URL}/supplier/${id}`); 
                setShowDelete(false)
                navigate('/suppliers')
                InfoToast('Supplier Deleted')
            } catch (error) {
                InfoToast(error.response)
            }
        }

  return (
    <>
        <Header title={`#${gardenInfo.invoiceNumber}`} RightContent={RightContent}/>
        {/* <div className=' flex justify-between  border-b border-[#e3e3e3] bg-white'>
            <div className='px-4'>
               <TabGroup setActiveTab={setActiveTab} activeTab={activeTab} options={[{label:'Orders',value:'orders'},{label:'Profile',value:'profile'}]}/>
            </div>
            <div>

            </div>
        </div> */}
        <div className='flex flex-1 overflow-scroll bg-white w-full '>
            <ProfileInfo info={gardenInfo}/>
            
        </div>
        { showEdit ? <ManageTea onClose={setShowEdit} onSubmit={onSubmit} edit={true} updateData={gardenInfo}/> : null}
    </>
  )
}

const ProfileInfo = ({info})=>
{
    
    return <div className='flex justify-start items-start h-[100%] w-full py-12 px-6'>
        <div className='grid grid-cols-3 gap-6 w-full'>
            <div>
                <label>Garden Name</label>
                <p className='text-sm font-medium'>{info?.garden.name}</p>
            </div>
            <div>
                <label>Tea Type</label>
                <p className='text-sm font-medium capitalize'>{info?.type} Tea</p>
            </div>
            <div>
                <label>Invoice Number</label>
                <p className='text-sm font-medium'>{info.invoiceNumber ? info.invoiceNumber : 'Not Available'}</p>
            </div>
            <div>
                <label>Contact Email</label>
                <p className='text-sm font-medium'>{info.contactEmail ? info.contactEmail : 'Not Available'}</p>
            </div>
            <div>
                <label>Address</label>
                <p className='text-sm font-medium'>{info?.address}</p>
            </div>
            <div>
                <label>Status</label>
                <p className='text-sm font-medium'>{info?.status === 0 || info?.status === '0' ? 'Active' : 'Inactive'}</p> 
            </div>
        </div>
    </div>
}

import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { dataURItoBlob } from "../../../helpers/utils"
import { data } from "../../dashboard/components/PriceData"
import SmallPopup from "../../../components/SmallPopup"

export default function InitiateManufacturing({onClose,edit=false,onSubmit})
{
    const [manageData,setManageData] = useState({id:edit.id,blendId:'',weightUsed:''})
    const formName = 'initiateManufacturing';
    const [blends,setBlends] = useState([])


    const getBlends = async()=>
    {
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/blend`)
        if(res.data.data) setBlends(res.data.data)
    }

    useEffect(()=>{
        getBlends()
    },[])

    console.log('item',edit)
    return <><Popup title={'Update Manufactured Quantity' } submitTitle={'Update'} onClose={onClose} formName={formName}>
        <form name={formName} onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div className="mb-4">
                <label>Select Blend*</label>
                <select required={true} value={manageData.blendId} onChange={(e)=>setManageData(data=>({...data,blendId:e.target.value}))}>
                    <option value={''}>Select Blend</option>
                    {
                        blends.map((item,index)=><option value={item.id}>{item.batchNumber} - {item.blendName} ({item.remainingWeight ? item.remainingWeight : 0} Kgs)</option>)
                    }
                </select>
            </div>
            <div>
                <label>Weight Used*</label>
                <Input type='number' placeholder={'Enter Weight Used'} required={true} value={manageData.weightUsed} setValue={(value)=>setManageData(data=>({...data,weightUsed:value}))}/>
            </div>
        </form>
    </Popup>
    </>
}
import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { useParams } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import { getDateFormat } from '../../helpers/utils'
import Progress from '../../components/Progress'
import UpdateProductionReceiving from './components/UpdateProductionReceivings'
import ShipPopup from './components/ShipPopup'
import ModalImage from 'react-modal-image'
import InitiateManufacturing from './components/InitiateManufacturing'

export default function ManufacturingOrderInfo() {

    const {id} = useParams();
    const [orders,setOrders] = useState({productionProducts:[]})
    const [orderInfo,setOrderInfo] = useState({productionProducts:[]})
    const [showShip,setShowShip] = useState(false)
    const [showUpdate,setShowUpdate] = useState({status:false,item:null})
    const [activeTab,setActiveTab] = useState('orders')
    const [manufacturing,setManufacturing] = useState(false)

    const RightContent = ()=>
    {
        return <div>
          {orderInfo.status === 'pending' ? <button type='button' className='btn-md mr-2' onClick={()=>setManufacturing(true)}>Intiate Manufacturing</button> : null}
          {orderInfo.progressPercentage >= 100 && orderInfo.status === 'finished' ? <button type='button' className='btn-md' onClick={()=>setShowShip(id)}>Ready to Ship</button> : null}
        </div>
    }

    async function getOrderInfo(){

        try 
        {
            let ordersRes = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/production/${id}`)
            if(ordersRes.data) setOrderInfo(ordersRes.data)
        } catch (error) {
            ErrorToast('Error getting Orders')
        }
    }
    useEffect(()=>
    {
        getOrderInfo();
    },[])

    const onShip = async(e,id)=>
        {
            try 
            {
                e.preventDefault()
    
                let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/shipping/${id}`); 
                await getOrderInfo()
                setShowShip(false)
                InfoToast('Shipping Order Created')
            } catch (error) {
                console.log('error',error)
                ErrorToast(error.response.data.error ? error.response.data.error : 'Error creating shipping order')
            }
        }

    const onReceiving = async(e,data)=>
        {
            try 
            {
                e.preventDefault()
    
                let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/production/receiving/${id}`,data); 
                await getOrderInfo()
                setShowUpdate({status:null,item:null})
                InfoToast('Receiving Updated')
            } catch (error) {
                console.log(error)
                InfoToast(error.response)
            }
        }


    const onInitiateManufacturing = async(e,data)=>
    {
        try 
        {
            e.preventDefault()

            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/production/initiate/${id}`,data); 
            await getOrderInfo()
            InfoToast('Manufacturing Initiated')
            setManufacturing(false)
        } catch (error) {
            console.log(error)
            setManufacturing(false)
            InfoToast(error.response)
        }
    }
  return (
    <>
        <Header title={`#${orderInfo?.salesOrder?.orderId}`} parentLink='/manufacturing' parentTitle='Manufacturing Order Management' RightContent={RightContent}/>
        {/* <div className=' flex justify-between border-b border-[#e3e3e3] bg-white'>
        </div> */}
        <div className='block flex-1 overflow-scroll bg-white w-full py-10'>
        <div className='grid grid-cols-4 gap-6 w-full border-b border-[#e3e3e3] pb-6 px-4 mb-10'>
            <div>
                <label className='uppercase font-medium text-[#959595] tracking-normal'>Order ID</label>
                <p className='text-sm text-black font-medium'>#{orderInfo?.orderId}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-[#959595] tracking-normal'>ordered Date</label>
                <p className='text-sm text-black font-medium'>{getDateFormat(orderInfo.orderDate)}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-[#959595] tracking-normal'>Expected Delivery Date</label>
                <p className='text-sm text-black font-medium'>{getDateFormat(orderInfo?.expectedDeliveryDate)}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-[#959595] tracking-normal'>Buyer Name</label>
                <p className='text-sm text-black font-medium'>{orderInfo?.salesOrder?.buyer?.name}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-[#959595] tracking-normal'>Contact Person</label>
                <p className='text-sm text-black font-medium'>{orderInfo.salesOrder?.buyer?.contactPerson ? orderInfo.salesOrder?.buyer.contactPerson : 'Not Available'}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-[#959595] tracking-normal'>Contact Number</label>
                <p className='text-sm text-black font-medium'>{orderInfo?.salesOrder?.buyer?.contactNumber ? orderInfo?.salesOrder?.buyer?.contactNumber : 'Not Available'}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-[#959595] tracking-normal'>Status</label>
                <p className='text-sm text-black font-medium capitalize'>{orderInfo?.status}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-[#959595] tracking-normal'>Blend Batch Number</label>
                <p className='text-sm text-black font-medium'>{orderInfo?.blend?.batchNumber ? orderInfo?.blend?.batchNumber : 'Not Available'}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-[#959595] tracking-normal'>Blend Name</label>
                <p className='text-sm text-black font-medium'>{orderInfo?.blend?.blendName ? orderInfo?.blend?.blendName : 'Not Available'}</p>
            </div>
            <div>
                <label className='uppercase font-medium text-[#959595] tracking-normal'>Blend Weight Used</label>
                <p className='text-sm text-black font-medium'>{orderInfo?.weightUsed ? `${orderInfo?.weightUsed} Kg` : 'Not Available'}</p>
            </div>

        </div>

        <div className='bg-white w-full flex-1 flex overflow-scroll mt-10'>
            <table className=' table-auto w-full flex-1 '>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    {/* <td><p className='cursor-pointer flex items-center hover:text-black'>SKU</p></td> */}
                
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Name/SKU</p></td>

                    <td><p className='cursor-pointer flex items-center hover:text-black'>Weight</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Progress</p></td>

                    <td><p className='cursor-pointer flex items-center hover:text-black'>Manf./Total Cartons</p></td>
                    

                    <td><p className='cursor-pointer flex items-center hover:text-black'>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        orderInfo.productionProducts.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize w-full'>
                                <div className="image-preview mr-2 ">
                                        <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.product.image}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.product.image}`} alt={item.product.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                                    </div>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.product.name}</p>
                                        <p className='text-[12px] text-[#959595] font-regular my-0'>#{item.product.sku}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.product.weight} Gms</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <Progress percentage={item.progressPercentage}/>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                    <p className='text-sm font-medium my-0'>{item.finishedCartons ? item.finishedCartons : 0}</p>
                                        <p className='text-[12px] text-[#959595] font-regular my-0'>{item.cartons}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>setShowUpdate({status:true,item:item})}>Update Quantity</button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
            
        </div>
        { showUpdate.status ? <UpdateProductionReceiving onClose={setShowUpdate} onSubmit={onReceiving} edit={showUpdate.item}/> : null}
        { showShip ? <ShipPopup onClose={setShowShip} onSubmit={onShip} edit={showShip}/> : null}
        { manufacturing ? <InitiateManufacturing onClose={setManufacturing} onSubmit={onInitiateManufacturing}/> : null}
    </>
  )
}